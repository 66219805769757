import { createRouter, createWebHashHistory } from 'vue-router'

export const routes = [
  {
    path: '/login',
    name: 'login',
    id: 'login',
    meta: {
      hiddenMenu: true,
      hiddenLayout: true
    },
    component: () => import('@/views/login.vue')
  },
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    id: 'home',
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/soldier-home',
    name: 'soldierHome',
    id: 'soldierHome',
    component: () => import('@/views/soldierHome.vue')
  },
  {
    path: '/resume',
    name: 'resume',
    id: 'resume',
    component: () => import('@/views/resume.vue')
  },
  {
    path: '/resume2',
    name: 'resume2',
    id: 'resume2',
    component: () => import('@/views/resume2.vue')
  },
  {
    path: '/company-registered',
    name: 'company-registered',
    id: 'company-registered',
    component: () => import('@/views/companyRegistered.vue')
  },
  {
    path: '/company-registered-next',
    name: 'company-registeredNext',
    id: 'company-registeredNext',
    component: () => import('@/views/companyRegisteredNext.vue')
  },
  {
    path: '/post-list',
    name: 'post-list',
    id: 'post-list',
    component: () => import('@/views/postList.vue'),
  },
  {
    path: '/gov-recruitment',
    name: 'gov-recruitment',
    id: 'gov-recruitment',
    component: () => import('@/views/govRecruitment.vue')
  },
  {
    path: '/gov-recruitmentNew',
    name: 'gov-recruitmentNew',
    id: 'gov-recruitmentNew',
    component: () => import('@/views/govRecruitmentNew.vue')
  },
  {
    path: '/gov-recruitment-detail',
    name: 'gov-recruitment-detail',
    id: 'gov-recruitment-detail',
    component: () => import('@/views/govRecruitmentDetail.vue')
  },
  {
    path: '/post-detail',
    name: 'post-detail',
    id: 'post-detail',
    component: () => import('@/views/postDetail.vue')
  },
  {
    path: '/resume-list',
    name: 'resume-list',
    id: 'resume-list',
    component: () => import('@/views/resumeList.vue')
  },
  {
    path: '/resume-detail',
    name: 'resume-detail',
    id: 'resume-detail',
    component: () => import('@/views/resumeDetail.vue')
  },
  {
    path: '/flex-resume-list',
    name: 'flex-resume-list',
    id: 'flex-resume-list',
    component: () => import('@/views/flexResumeList.vue')
  },
  {
    path: '/flex-resume-detail',
    name: 'flex-resume-detail',
    id: 'flex-resume-detail',
    component: () => import('@/views/flexResumeDetail.vue')
  },
  {
    path: '/flex-post-list',
    name: 'flex-post-list',
    id: 'flex-post-list',
    component: () => import('@/views/flexPostList.vue')
  },
  {
    path: '/flex-post-detail',
    name: 'flex-post-detail',
    id: 'flex-post-detail',
    component: () => import('@/views/flexPostDetail.vue')
  },
  {
    path: '/company-info',
    name: 'companyInfo',
    id: 'companyInfo',
    component: () => import('@/views/companyInfo.vue')
  },
  {
    path: '/flex-home',
    name: 'flexHome',
    id: 'flexHome',
    component: () => import('@/views/flexHome.vue')
  },
  {
    path: '/video-list',
    name: 'videoList',
    id: 'videoList',
    component: () => import('@/views/videoList.vue')
  },
  {
    path: '/user-agreement',
    name: 'userAgreement',
    id: 'userAgreement',
    component: () => import('@/views/userAgreement.vue'),
    meta: {
      hiddenFooter: true
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    id: 'privacy',
    component: () => import('@/views/privacy.vue'),
    meta: {
      hiddenFooter: true
    }
  },
  {
    path: '/vip-intro',
    name: 'vip-intro',
    id: 'vip-intro',
    component: () => import('@/views/JoinVip.vue'),
  }
]

const router = createRouter({
  // mode: 'history',
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 }
  },
})

router.beforeEach(
  async (
    to,
    from,
    next
  ) => {
    const blackList = []
    if (to.name && blackList.length && to.name.indexOf(blackList) > -1) {
      window.location.href='/#/404'
    } else {
      next()
    }
  }
);


export default router
